import {
  EditJob,
  HandShakeSimpleIcon,
  RepostQuoteIcon,
  SharedConnectionIcon,
} from '@mybridge/icons';
import { CopyIcon } from '@mybridge/icons/Copy';
import { RepostIcon } from '@mybridge/icons/Repost';
import { QuoteIcon } from '@mybridge/icons/Quote';
import { Button } from '@mybridge/ui/button';
import { useToast } from '@mybridge/ui/hooks';
import { HStack } from '@mybridge/ui/layout';
import { Menu, MenuButton, MenuItem, MenuList } from '@mybridge/ui/menu';
import { Text } from '@mybridge/ui/text';
import { useContext } from 'react';
import { copyTextToClipboard, getDomainName } from 'v4/lib/commons';
import { PostBoxContext } from '../context';
import styles from './reel-footer.module.scss';

export const PostShareMenu = ({ isReel }) => {
  const { post, postModalLink, repostModal, quotePostModal } =
    useContext(PostBoxContext);
  const toast = useToast();
  
  const copyPostLink = () => {
    if (typeof window === undefined) return;
    copyTextToClipboard(getDomainName() + postModalLink?.(post?.id));
    toast?.({
      title: 'Link copied successfully!',
      status: 'success',
      position: 'top',
      duration: 10000,  // Set the duration to 10 seconds (10000 ms)
      isClosable: true, // Make the toast closable
    });
  };

  const { author } = post ?? {};
  
  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          width={isReel ? '' : '25%'}
          className={isReel ? styles.reelBtn : ''}
          variant="ghost"
        >
          <HStack justifyContent="center">
            <SharedConnectionIcon color={isReel ? 'white' : '#5B5B5B'} />
            {!isReel && <Text display={['none', 'none', 'block']}>Share</Text>}
          </HStack>
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={(e) => repostModal?.onOpen?.()}
            icon={<RepostIcon width={20} height={20} />}
          >
            Repost
          </MenuItem>
          <MenuItem
            onClick={(e) => {quotePostModal?.onOpen?.()}}
            icon={<QuoteIcon width={20} height={20} />}
          >
            Quote Post
          </MenuItem>
          <MenuItem
            onClick={copyPostLink}
            icon={<CopyIcon width={20} height={20} />}
          >
            Copy Link
          </MenuItem>
          {/* <MenuItem icon={<BlockIcon width={20} height={20} />}>Share</MenuItem> */}
        </MenuList>
      </Menu>
    </>
  );
};

import moment from 'moment';
import SparkMD5 from 'spark-md5';

const rangeOfFullYearsWithFuture = (start, end) => {
  const fullYears = (end.getTime() - start.getTime()) / 31536000000;
  return Array(end.getFullYear() - start.getFullYear() + 1)
    .fill(start.getFullYear())
    .map((year, index) => year + index)
    .filter((year, index) => index <= fullYears)
    .map((year) => ({ label: year, value: year }));
};

const rangeOfFullYears = (startYear = 1920, type = 'forward') => {
  let currentYear = new Date().getFullYear();
  const yearRange = [];

  if (type === 'forward') {
    while (startYear <= currentYear) {
      const year = startYear++;
      yearRange.push({ label: year, value: year });
    }
  } else {
    while (currentYear >= startYear) {
      const year = currentYear--;
      yearRange.push({ label: year, value: year });
    }
  }

  return yearRange;
};

// const days = Array.from({length: 31}, (_, i) => i + 1).map(val => ({value:val, label: val}))

const getDaysInMonth = (month, year) => {
  // var date = new Date(year, month, 1);
  var date = new Date(Date.UTC(year, month, 1));
  var days = [];
  while (date.getUTCMonth() === month) {
    days.push(date.getUTCDate());
    date.setUTCDate(date.getUTCDate() + 1);
  }
  let finalDaysData = days.map((val) => ({ value: val, label: val }));

  return finalDaysData;
};

const meetingsByDate = (meetings) =>
  meetings?.reduce((acc, meeting) => {
    const date = new Date(meeting?.start_time);
    const key = isToday(date)
      ? 'Today'
      : isTomorrow(date)
      ? 'Tomorrow'
      : moment(date).format('ddd MMM D, YYYY');
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(meeting);
    return acc;
  }, {});

const todayDate = moment().format('YYYY-MM-DD');
const presentTime = moment();
const tomorrowDate = moment().startOf('day').add(1, 'day').format('YYYY-MM-DD');
const endOfToday = moment().endOf('day');

const isToday = (meetingDate) => {
  return moment(meetingDate).format('YYYY-MM-DD') === todayDate;
};

const isTomorrow = (meetingDate) => {
  return moment(meetingDate).format('YYYY-MM-DD') === tomorrowDate;
};

const isAfterTomorrow = (meetingDate) => {
  return moment(meetingDate).isAfter(tomorrowDate);
};

const isAfterPresentTimeAndToday = (meetingDate) => {
  return (
    moment(meetingDate).isAfter(presentTime) &&
    moment(meetingDate).isBefore(endOfToday)
  );
};

const isAfterPresentTime = (meetingDate) => {
  return moment(meetingDate).isAfter(presentTime);
};

const isBeforeToday = (meetingDate) => {
  return moment(meetingDate).isBefore(todayDate);
};

/**
 * Trims a long string and adds ellipsis(...) at the end
 * @param {*} str
 * @param {*} opts {length(number), appendSuffix(boolean), suffix(string)}
 * @returns
 */
export const trimStr = (str, opts) => {
  const { length = 20, appendSuffix = true, suffix = '...' } = opts || {};
  return str?.length > length
    ? str?.substring(0, length - 1) + (appendSuffix ? suffix : '')
    : str;
};

/**
 * Convers a string to pascal case
 * @param {*} str
 * @returns
 */
export const toPascalCase = (str) => {
  return str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

const isImageURL = (url) => {
  return /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg)/g.test(url);
};

/**
 * Generate random ID of specific length
 * @param {*} length
 * @returns
 */
export const randomId = (length = 5) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    document.body.removeChild(textArea);
    return successful;
  } catch (err) {
    document.body.removeChild(textArea);
    console.error('Fallback: Oops, unable to copy', err);
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}

export async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  await navigator.clipboard.writeText(text);
  return true;
}

export const currencyFormatter = (number, currency = 'USD') => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
};

export const convertToSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export {
  rangeOfFullYears,
  getDaysInMonth,
  rangeOfFullYearsWithFuture,
  isToday,
  isTomorrow,
  isAfterTomorrow,
  isAfterPresentTimeAndToday,
  isBeforeToday,
  isAfterPresentTime,
  meetingsByDate,
  isImageURL,
};

export const calculateChecksum = async (file, chunkSize) => {
  return new Promise((res, rej) => {
    let md5;
    let slice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice,
      chunks = Math.ceil(file.size / chunkSize),
      current_chunk = 0,
      spark = new SparkMD5.ArrayBuffer();

    function onload(e) {
      try {
        spark.append(e.target.result); // append chunk
        current_chunk++;
        if (current_chunk < chunks) {
          read_next_chunk();
        } else {
          md5 = spark.end();
          res({ md5, totalChunks: chunks });
        }
      } catch (e) {
        rej(e);
      }
    }

    function read_next_chunk() {
      try {
        var reader = new FileReader();
        reader.onload = onload;
        var start = current_chunk * chunkSize,
          end = Math.min(start + chunkSize, file.size);
        reader.readAsArrayBuffer(slice.call(file, start, end));
      } catch (e) {
        rej(e);
      }
    }
    read_next_chunk();
  });
};

export const postHasInsights = (post) => {
  const { like_count, dislike_count, comment_count, reposts_by } = post ?? {};
  return (
    (like_count ?? 0) + (dislike_count ?? 0) > 0 ||
    comment_count > 0 ||
    reposts_by?.length > 0
  );
};

export const cleanHTML = (str, nodes) => {
  // Do stuff here...
  function stringToHTML() {
    let parser = new DOMParser();
    let doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  }

  // Convert the string to HTML
  let html = stringToHTML();
  return html;
};

export const imageLinkToFileObject = async (url, props) => {
  return new Promise((res, rej) => {
    fetch(url)
      .then(async (response) => {
        const contentType = props?.type ?? response.headers.get('content-type');
        const blob = await response.blob();
        const file = new File([blob], props?.fileName ?? randomId(), {
          contentType,
        });
        res(file);
        // access file here
      })
      .catch((e) => {
        rej(e);
      });
  });
};

export const getFileExtension = (fileName) => {
  return fileName?.substring(fileName?.lastIndexOf('.') + 1);
};

export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
}

export const isDir = (file) => file?.type === 'D';
export const isRootDir = (file) => file?.type === 'RD' && !file?.team_info?.id;
export const isTeamDir = (file) => file?.type === 'TD';
export const isSharedUser = (file) => file?.email || file?.slug;
export const isFile = (file) => file?.type === 'F';

import { Box } from '@mybridge/ui';
import { forwardRef } from 'react';
export const MATRIX_PRESENCE = {
  online: {
    bgColor: 'green.500',
    priority: 2,
  },
  busy: {
    bgColor: 'red.500',
    priority: 1,
  },
  offline: {
    bgColor: 'gray.500',
    priority: 0,
  },
};
export const UserPresenceDot = forwardRef(
  ({ children, user, wrapperProps, ...props }, ref) => {
    return (
      <>
        <Box pos="relative" {...(wrapperProps ?? {})}>
          {children}
          <Box
            pos="absolute"
            bottom="0"
            right="0"
            borderRadius="full"
            w="3"
            h="3"
            border="2px solid white"
            {...(MATRIX_PRESENCE[user?.matrix_presence] ?? {})}
            {...(props ?? {})}
          />
        </Box>
      </>
    );
  }
);
